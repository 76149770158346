/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third Party
import styled from 'styled-components'

const StyledProjectInfo = styled.div`
  box-shadow: 0px 0px 30px -15px rgba(0,0,0,0.75);
  background-color: ${props => props.theme.color.secondary};
  width: 280px;
  top: -80px;

  @media screen and (max-width: 991px) {
   top: -96px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    top: 0;
  }

  h2 {
    font-size: ${props => props.theme.font.size.xm};
    border-bottom: 1px solid ${props => props.theme.color.main};
    padding-bottom: 15px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 3rem;
      bottom: -1px;
      background-color: ${props => props.theme.color.main};
    }

    @media screen and (max-width: 768px) {
      &:before {
        content: none;
      }
    }
  }

  h3 {
    font-size: ${props => props.theme.font.size.sm};
  }

  p {
    font-size: ${props => props.theme.font.size.sm};
  }
`

const Dot = styled.p`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 3px;
    left: 0;
    top: 9px;
    border-radius: 100px;
    background-color: ${props => props.theme.color.gray};
  }
`

const ProjectInfo = ({ className, info }) => {
  return (
    <StyledProjectInfo className={`position-relative px-4 py-4 ${className ? `${className}` : ``}`}>
      <h2 className="mb-4 text-uppercase color-text-gray font-weight-xl">Projectgegevens</h2>
      <h3 className="text-uppercase color-text-contrast font-weight-s">Opdrachtgever</h3>
      <p className="mb-4 color-text-secondary font-weight-s">{info.assigner}</p>
      <h3 className="text-uppercase color-text-contrast font-weight-s">Architect</h3>
      <p className="mb-4 color-text-secondary font-weight-s">{info.architect}</p>
      <h3 className="text-uppercase color-text-contrast font-weight-s">Uitvoering I.S.M.</h3>
      {info.collab.map((text, index) => (
        <p key={index} className="mb-0 color-text-secondary font-weight-s">{text.text}</p>
      ))}
      <h3 className="mt-4 text-uppercase color-text-contrast font-weight-s">Werkzaamheden SSB</h3>
      {info.what_we_do.map((text, index) => (
        <Dot key={index} className="pl-2 mb-0 color-text-secondary font-weight-s">{text.text}</Dot>
      ))}
      <h3 className="mt-4 text-uppercase color-text-contrast font-weight-s">Status</h3>
      <p className="color-text-secondary font-weight-s">{info.status}</p>
    </StyledProjectInfo>
  )
}

export default ProjectInfo