import React from 'react'
import Img from 'gatsby-image'

// Third Party
import styled, { css } from 'styled-components'
import ReactPlayer from 'react-player/lazy'

const StyledVideo = styled.div`
  padding-bottom: 130px;
  padding-top: 130px;
`

const StyledBackgroundImg = styled(Img)`
  position: absolute !important;
  height: 140%;
  width: 1000px;
  z-index: -1;
  top: -50px;

  ${props => props.left && css`
    left: -300px;
  `}

  ${props => props.right && css`
    right: -300px;
  `}
`

const Content = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.xxl};
  }

  p {
    line-height: 30px;
    color: ${props => props.theme.color.text.secondary};
  }
`

const BlueBox = styled.div`
  background: ${props => props.theme.color.gradient_background};
  background: ${props => props.theme.color.gradient};
  position: relative;
  right: 10px;

  height: 337px;
  width: 600px;

  @media screen and (max-width: 656px) {
    height: 168px;
    width: 310px;
  }

  @media screen and (max-width: 335px) {
    height: 135px;
    width: 250px;
  }
`

const StyledPlayer = styled(ReactPlayer)`
  position: relative;
  background-color: #000000;
  top: 20px;
  left: 20px;
  height: 100%;
  width: 100%;
`

const Video = ({ className, video }) => {
  return (
    <StyledVideo className={`${className ? ` ${className}` : ``}`}>
      <div className="row">
        <div className="col-lg-12 px-5 d-flex justify-content-center align-items-center">
          <Content className="px-4">
            <h2 className="pb-4 text-center color-text-contrast font-weight-xl">{video.title}</h2>
            <div className="d-flex justify-content-center">
              <BlueBox>
                <StyledPlayer width="100%" height="100%" controls url={video.video} />
              </BlueBox>
            </div>
          </Content>
          <StyledBackgroundImg right fluid={video.image.localFile.childImageSharp.fluid} alt="" />
        </div>
      </div>
    </StyledVideo>
  )
}

export default Video