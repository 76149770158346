/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import CustomLink from 'components/shared/CustomLink'
import ProjectInfo from 'components/projects/ProjectInfo'
import ProjectImages from 'components/projects/ProjectImages'
import Video from 'components/projects/Video'

// Third Party
import styled from 'styled-components'

const StyledCustomLink = styled(CustomLink)`
  p {
    font-size: ${props => props.theme.font.size.sm};
  }
`

const BigImage = styled(Img)``

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressWpProject(wordpress_id: { eq: $wordpress_id }) {
      ...ProjectFrag
    }
  }
`

const InfoContainer = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const PageTemplate = ({
  data: {
    page: {
      path,
      yoastMeta,
      acf: {
        banner,
        images,
        info,
        video_group: video
      }
    },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />
      <Hero className="mb-3" detail content={banner} />
      <div className="container pb-5">
        <div className="row">
          <div className="col-md-8">
            <StyledCustomLink to="/projecten">
              <p className="text-uppercase font-weight-xl color-text-gray">{`< Terug naar het overzicht`}</p>
            </StyledCustomLink>
            <BigImage fluid={images.head_image.localFile.childImageSharp.fluid} alt="" />
          </div>
          <div className="col-md-4 d-flex justify-content-lg-end justify-content-center pt-3 pt-lg-0">
            <InfoContainer>
              <ProjectInfo info={info} />
            </InfoContainer>
          </div>
          <div className="col-12 py-3">
            {images.other_images && (
              <ProjectImages images={images.other_images} />
            )}
          </div>
          {video && video.video && (
            <div className="col-12 py-3">
              <Video video={video} />
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate
