/* eslint-disable react/no-array-index-key */
import React from 'react'
import Img from 'gatsby-image'

// Third Party
import styled from 'styled-components'
import Slider from 'react-slick'

const StyledImg = styled(Img)``

const StyledProjectImages = styled.div``

const ProjectImages = ({ className, images }) => {
  return (
    <StyledProjectImages className={`${className ? `${className}` : ``}`}>
      {
      {
        0: <div className="py-3" />,
        1: <OnePhoto className="py-2" images={images} />,
        2: <TwoPhoto className="py-2" images={images} />,
        3: <MultiplePhotos images={images} />,
        4: <MultiplePhotos images={images} />
      }[images.length]
      }
    </StyledProjectImages>
  )
}

export default ProjectImages

const StyledOnePhoto = styled.div`
  ${StyledImg} {
    max-height: 360px;
  }
`

const OnePhoto = ({ className, images }) => {
  return (
    <StyledOnePhoto className={`${className ? `${className}` : ``}`}>
      <div className="row">
        <div className="col-lg-6">
          <StyledImg fluid={images[0].image.localFile.childImageSharp.fluid} alt="" />
        </div>
      </div>
    </StyledOnePhoto>
  )
}

const StyledTwoPhoto = styled.div`
  ${StyledImg} {
    max-height: 360px;
  }
`

const TwoPhoto = ({ className, images }) => {
  return (
    <StyledTwoPhoto className={`${className ? `${className}` : ``}`}>
      <div className="row d-lg-flex d-none">
        <div className="col-lg-6">
          <StyledImg fluid={images[0].image.localFile.childImageSharp.fluid} alt="" />
        </div>
        <div className="col-lg-6">
          <StyledImg fluid={images[1].image.localFile.childImageSharp.fluid} alt="" />
        </div>
      </div>
      <div className="row d-lg-none d-flex">
        <div className="col-lg-12">
          <StyledSlider 
            infinite
            speed={200}
            slidesToShow={2}
            slidesToScroll={1}
            autoplay
            autoplaySpeed={8000}
            responsive={[
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
              },
            ]}
          >
            {images.map((image, index) => (
              <ImgContainer>
                <StyledImg key={index} fluid={image.image.localFile.childImageSharp.fluid} alt="" />
              </ImgContainer>
            ))}
          </StyledSlider>
        </div>
      </div>
    </StyledTwoPhoto>
  )
}

const StyledMultiplePhotos = styled.div``

const StyledSlider = styled(Slider)`
  overflow: hidden;

  .slick-slide {
    margin: 0 5px;
  }

  .slick-list {
    overflow: hidden;
    margin: 0 -5px;
  }

  .slick-track {
    display: flex;
  }
`

const ImgContainer = styled.div`
  width: 100%;
  height: 360px;

  ${StyledImg} {
    width: 100%;
    height: 100%;
  }
`

const MultiplePhotos = ({ className, images }) => {
  return (
    <StyledMultiplePhotos className={`${className ? `${className}` : ``}`}>
      <StyledSlider 
        infinite
        speed={200}
        slidesToShow={2}
        slidesToScroll={1}
        autoplay
        autoplaySpeed={8000}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
        ]}
      >
        {images.map((image, index) => (
          <ImgContainer>
            <StyledImg key={index} fluid={image.image.localFile.childImageSharp.fluid} alt="" />
          </ImgContainer>
        ))}
      </StyledSlider>
    </StyledMultiplePhotos>
  )
}